import React from 'react';
import Avatar from '../../ui/Avatar';
import Card from '../../ui/Card';
import MessageItem from './components/MessageItem';

interface Props {
    messages: any[]
}

const MyConversations:React.FC<Props> = ({messages}) => {
    return (
        <div className='h-1/2'>
            <Card className='h-full overflow-scroll'>
                <h4 className='text-lg mb-3'>My Conversations</h4>
                {messages.slice(0,4).map((message) => (
                    <MessageItem 
                        key={message.id}
                        id={message.id}
                        username={message.username} 
                        last_message={message.last_message.text}
                        time={message.last_message.time}
                        avatar={message.avatar}
                    />
                ))}
            </Card>
        </div>
    );
};

export default MyConversations;