export const Opps = [
    {
        id: 1,
        image: "/media/misc/Junior-bookkeeper.jpg",
        title: 'Junior Bookkeeper',
        salary: 'R4600/m',
        views: '20',
        date_posted: '3 minutes',
        oppChartData: {
            applications: 1005,
            barChartData: [50, 60, 70, 80, 60, 50, 70, 60],
            pieChartData: [30,40,15,6],
        }
    },
    {
        id: 2,
        image: "/media/misc/pexels-jeswin-thomas-3781338.jpg",
        title: 'Senior Actuary',
        salary: 'R410/h',
        views: '112',
        date_posted: '1 hour',
        oppChartData: {
            applications: 495,
            barChartData: [70, 60, 30, 50, 100, 20, 30, 80],
            pieChartData: [50,20,55,60],
        }
    },
    {
        id: 3,
        image: "/media/misc/Graphic-designer.jpg",
        title: 'Graphics Designer',
        salary: 'R127/h',
        views: '106',
        date_posted: '1 hour',
        oppChartData: {
            applications: 1805,
            barChartData: [10, 60, 30, 50, 60, 50, 90, 40],
            pieChartData: [3,40,25,16],
        }
    },
    {
        id: 4,
        image: "/media/misc/Model.jpg",
        title: 'Model',
        salary: 'R89/h',
        views: '464',
        date_posted: '2 hour',
        oppChartData: {
            applications: 3095,
            barChartData: [80, 50, 70, 80, 60, 60, 70, 80],
            pieChartData: [15,20,45,60],
        }
    },
    {
        id: 5,
        image: "/media/misc/Legal-Executive.jpg",
        title: 'Legal Advisor',
        salary: 'R325/h',
        views: '1K',
        date_posted: '4 days',
        oppChartData: {
            applications: 240,
            barChartData: [30, 50, 10, 80, 40, 30, 20, 90],
            pieChartData: [35,14,25,46],
        }
    },
    {
        id: 6,
        image: "/media/misc/receptionist.jpg",
        title: 'Receptionist',
        salary: 'R6300/m',
        views: '933',
        date_posted: '5 days',
        oppChartData: {
            applications: 4285,
            barChartData: [80, 70, 60, 80, 40, 90, 100, 75],
            pieChartData: [30,40,15,6],
        }
    },
]

export const MESSAGES = [
    {
        id: 1,
        username: 'Francis',
        last_message: { 
            text:'Hi, how are you doing?', 
            time: '19:30'
        },
        avatar: 'avatar-04.jpg',
        status: 'Online',
        reply: {
            text: 'adfhak',
            time: 'Now'
        }
    },
    {
        id: 2,
        username: 'Sam',
        last_message: { 
            text:'I sent you the files.', 
            time: '10:50'
        },
        avatar: 'avatar-05.jpg',
        status: 'typing...',
        reply: {
            text: 'adfhak',
            time: 'Now'
        }
    },
    {
        id: 3,
        username: 'Angela',
        last_message: { 
            text:'Hello there!', 
            time: '08:45'
        },
        avatar: 'avatar-07.jpg',
        status: 'Last seen at 02:38',
        reply: {
            text: 'adfhak',
            time: 'Now'
        }
    },
    {
        id: 4,
        username: 'Thabo',
        last_message: { 
            text:'When are you free for call?', 
            time: '12:00'
        },
        avatar: 'avatar-01.jpg',
        status: 'Online',
        reply: {
            text: 'adfhak',
            time: 'Now'
        }
    },
    {
        id: 5,
        username: 'Farai',
        last_message: { 
            text:'Hey!', 
            time: 'Now'
        },
        avatar: 'avatar-10.jpg',
        status: 'Last seen Thu',
        reply: {
            text: 'adfhak',
            time: 'Now'
        }
    },
    {
        id: 6,
        username: 'Simba',
        last_message: { 
            text:'Wanna grab some lunch pal?', 
            time: '11:45'
        },
        avatar: 'avatar-06.jpg',
        status: 'typing...',
        reply: {
            text: 'adfhak',
            time: 'Now'
        }
    },
    {
        id: 7,
        username: 'Gwen',
        last_message: { 
            text:'Please can you resend the email.', 
            time: '21:20'
        },
        avatar: 'avatar-09.jpg',
        status: 'Online',
        reply: {
            text: 'adfhak',
            time: 'Now'
        }
    },
]
