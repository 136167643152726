/* eslint-disable react-hooks/exhaustive-deps */

import { Header } from "./Header";


export function HeaderWrapper() {



  return (
    <div>
      <Header />
    </div>
  )
}
