import clsx from 'clsx';
import React from 'react';

interface Props {
    className?: string
    avatar?: string
}

const AvatarBox: React.FC<Props> = ({className, avatar}) => {
    return (
        <div
            className={clsx('rounded-xl', className)}
            style={{
                background: `${avatar ? `url(/media/avatars/${avatar})` : `url(/media/avatars/avatar-02.jpg)`}`,
                backgroundSize: 'cover', 
                backgroundPosition: 'top center'
            }}
        >
        </div>
    );
};

export default AvatarBox;