import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step3: FC = () => {
  return (
    <div className='w-full'>
      <div className='pb-10 lg:pb-15'>
        <h2 className='font-bold flex items-center text-dark text-xl'>
          Rates
        </h2>
      </div>

      <p>Logic for rates will come here</p>    
    </div>
  )
}

export {Step3}
