import { SVG } from '@/lib'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'

const Step6: FC = () => {
  return (
    <div className='w-full'>
      <div className='pb-10 lg:pb-15'>
        <h2 className='font-bold flex items-center text-dark text-xl'>
          You Are Done!
        </h2>
      </div>

      <p>Interface for completed state will come here.</p>    
    </div>
  )
}

export {Step6}
