import Avatar from '@/components/ui/Avatar';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    username: string
    last_message: string
    id?: number,
    avatar?: string
    time?: string
}
const MessageItem: React.FC<Props> = ({username, last_message, avatar, time, id}) => {
    return (
        <>
            <div className='flex gap-4 w-full'>
                <Avatar className='w-16' avatar={avatar}/>
                <Link to={`/chats/${id}`} className='flex flex-col justify-center w-full'>
                    <div className="flex items-center justify-between w-full">
                        <h6 className='font-[600] text-[16px]'>{username}</h6>
                        <span className='text-xs text-slate-400/80'>{time ? time : '00:00'}</span>
                    </div>
                    <p className='text-slate-400 font-[400] text-sm'>{last_message}</p>
                </Link>
            </div>
            <div className='w-full h-[1px] bg-gray-300 my-3'></div>
        </>
    );
};

export default MessageItem;